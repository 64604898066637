<template>
  <div forgot-template>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'ForgotTemplate'
};
</script>

<style lang="less">
@import '~@/less/proj.less';
</style>